export const STATUS_MAP = {
  0: "Pending approval",
  1: "Rejected",
  2: "Completed",
  3: "In progress",
  4: "Approved",
  5: "Cancel",
  6: "Failed",
};

export const fileTemplates = [
  {
    fileName: "Data-Policy-Template.xlsx",
    fileUrl: "/Data-Policy-Template.xlsx",
  },
  {
    fileName: "Data-Batch-Publishing-Template.xlsx",
    fileUrl: "/Data-Batch-Publishing-Template.xlsx",
  },
];
