import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

const Frequently = ({ favoritLlinks }) => {
  return (
    <div className={styles.frequently}>
      {favoritLlinks.map((item) => {
        return (
          <div className={styles.frequentForm}>
            <Link
              className={styles.link}
              key={`form-${item.id}`}
              to={item.link}
            >
              {item.title}
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Frequently;
