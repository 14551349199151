import React from "react";
import { Box, Container, Typography } from "@material-ui/core";
import Page from "src/components/Page";
import styles from "./styles.module.scss";
import LockIcon from "@material-ui/icons/Lock";

const NotAuthorized = () => {
  return (
    <Page className={styles.root} title="403">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            You are not authorized to access this page
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation
          </Typography>
          <Box textAlign="center" className={styles.lockIcon}>
            <LockIcon />
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default NotAuthorized;
