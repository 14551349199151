/* third lib*/
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { FormattedMessage as Intl } from "react-intl";
import { useForm } from "react-hook-form";
import cn from "classnames";
import { useNavigate } from "react-router-dom";

/* material-ui */
import TablePagination from "@material-ui/core/TablePagination";

/* local components & methods */
import Text from "@basics/Text";
import styles from "./styles.module.scss";
import FormItem from "@comp/FormItem";
import HeadLine from "@basics/HeadLine";
import Search from "@basics/Search";
import CallModal from "@basics/CallModal";
import Loading from "@assets/icons/Loading";
import { getTableData } from "@lib/api";
import Button from "@basics/Button";
import { sendNotify } from "src/utils/systerm-error";
import CardItem from "./CardItem";

const tableForm = [
  {
    default: "",
    des: "Dataset name",
    edit: 1,
    id: "datasetId",
    label: "Dataset name",
    options: [],
    placeholder: "Dataset name",
    style: 3,
  },
  {
    default: "",
    des: "Table description",
    edit: 1,
    id: "tableDescription",
    label: "Table description",
    options: [],
    placeholder: "Table description",
    style: 3,
  },
  {
    default: "",
    des: "Location",
    edit: 1,
    id: "location",
    label: "Location",
    options: [],
    placeholder: "Location",
    style: 3,
  },
  {
    default: "",
    des: "Column name",
    edit: 1,
    id: "columnName",
    label: "Column name",
    options: [],
    placeholder: "Column name",
    style: 3,
  },
  {
    default: "",
    des: "Included table tag",
    edit: 1,
    id: "tags",
    label: "Included table tag",
    options: [],
    placeholder: "Included table tag",
    style: 3,
  },
  {
    default: "",
    des: "Included policy tag",
    edit: 1,
    id: "policyTags",
    label: "Included policy tag",
    options: [],
    placeholder: "Included policy tag",
    style: 3,
  },
  {
    default: "",
    des: "Create time",
    edit: 1,
    id: "createTime",
    label: "Create time",
    options: [],
    placeholder: "Create time",
    style: 9,
  },
  {
    default: "",
    des: "Onboard time",
    edit: 1,
    id: "onboardTime",
    label: "Onboard time",
    options: [],
    placeholder: "Onboard time",
    style: 9,
  },
  {
    default: "",
    des: "Type",
    edit: 1,
    id: "type",
    label: "Type",
    options: [
      { label: "TABLE", value: "TABLE" },
      { label: "View", value: "View" },
    ],
    placeholder: "",
    style: 1,
  },
];

const DataDiscovery = () => {
  const navigate = useNavigate();
  const { handleSubmit, control, register, reset } = useForm(); // initialise the hook

  const [formLoading, setFormLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [tableList, setTableList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [dataName, setDataName] = useState("");

  const [modalData, setModalData] = useState({
    open: false,
    status: 0,
    content: "",
    cb: null,
  });

  const filterTableList = useMemo(() => {
    if (!tableList) {
      return [];
    }
    let tmpList = tableList;
    return tmpList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [tableList, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const submitHandle = useCallback(
    (data) => {
      Object.keys(data).forEach((key) => {
        !data[key] && delete data[key];
      });

      if (dataName) {
        data.tableId = dataName;
      }
      setSearchQuery(data);
    },
    [dataName]
  );

  const renderFormItem = (items, disabled) => {
    return items.map((item, index) => {
      return (
        <FormItem
          key={index}
          data={item}
          index={index}
          control={control}
          register={register}
          disabled={disabled}
        />
      );
    });
  };

  const closeModal = () => {
    setModalData({ ...modalData, open: false, cb: null });
  };

  useEffect(() => {
    if (searchQuery) {
      setFormLoading(true);
      getTableData(searchQuery)
        .then((res) => {
          setTableList(res.data);
          setFormLoading(false);
        })
        .catch((e) => {
          sendNotify({ msg: e.message, status: 3, show: true });
        });
    }
  }, [searchQuery]);

  useEffect(() => {
    setFormLoading(true);
    getTableData()
      .then((res) => {
        setTableList(res.data);
        setFormLoading(false);
      })
      .catch((e) => {
        sendNotify({ msg: e.message, status: 3, show: true });
      });
  }, []);

  const handleSearch = useCallback(() => {
    setFormLoading(true);
    getTableData(searchQuery)
      .then((res) => {
        setTableList(res.data);
        setFormLoading(false);
      })
      .catch((e) => {
        sendNotify({ msg: e.message, status: 3, show: true });
      });
  }, [searchQuery]);

  return (
    <div className={styles.dataDiscover}>
      <div className={styles.title}>
        <HeadLine>
          <Intl id="dataExplore" />
        </HeadLine>
      </div>
      <form
        className={styles.tableSearch}
        id="tableSearch"
        onSubmit={handleSubmit(submitHandle)}
      >
        <div className={styles.dataContainer}>
          <div className={styles.filterContianer}>
            <div className={styles.filterPanel}>
              <div className={styles.formOptions}>
                {renderFormItem(tableForm)}
              </div>
            </div>
          </div>
          <div className={styles.filterBox}>
            <div className={styles.filterItem}>
              <Search
                fullWidth
                placeholder="Search by Table name"
                value={dataName}
                onChange={(value) => {
                  setDataName(value);
                }}
                handleSearch={handleSearch}
              />
              <div className={styles.buttonWrapper}>
                <Button
                  onClick={() => {
                    navigate(`/app/dashboard`);
                  }}
                  className={styles.button}
                  text
                >
                  <Intl id="backToDashboard" />
                </Button>
                <Button
                  className={styles.button}
                  type="submit"
                  variant="contained"
                >
                  <Intl id="search" />
                </Button>
                <div
                  className={styles.reset}
                  onClick={() => {
                    setDataName("");
                    reset({
                      datasetId: "",
                      tableDescription: "",
                      location: "",
                      columnName: "",
                      tags: "",
                      policyTag: "",
                      createTime: "",
                      onboardTime: "",
                      type: "",
                    });
                    setSearchQuery({});
                    setTableList([]);
                    setPage(0);
                  }}
                >
                  <Text type="regular">
                    <Intl id="CLEAR" />
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.mainContent}>
            <div
              className={cn(styles.dataTable, {
                [styles["tableLoading"]]: formLoading,
              })}
            >
              {formLoading && <Loading></Loading>}
              {!formLoading &&
                filterTableList.map((item) => {
                  return <CardItem key={item.id} cardData={item} />;
                })}
            </div>

            <TablePagination
              rowsPerPageOptions={[6, 12, 24]}
              component="div"
              count={tableList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </form>

      <CallModal
        open={modalData.open}
        content={modalData.content}
        status={modalData.status}
        buttonClickHandle={modalData.cb}
        handleClose={closeModal}
      />
    </div>
  );
};

export default DataDiscovery;
