/* third lib*/
import React, { useState } from "react";
import { FormattedMessage as Intl } from "react-intl";
import { useNavigate } from "react-router-dom";

/* local components & methods */
import styles from "./styles.module.scss";
import TagTemplateList from "./TagTemplateList";
import PolicyTagTable from "./PolicyTagTable";
import CallModal from "@basics/CallModal";
import Button from "@basics/Button";
import Policy from "./Policy";

const PolicyCreation = () => {
  const navigate = useNavigate();
  const [currentId, setCurrentId] = useState(null);
  const [step, setStep] = useState(0);

  const [modalData, setModalData] = useState({
    open: false,
    status: 0,
    content: "",
    cb: null,
  });

  const closeModal = () => {
    setModalData({ ...modalData, open: false, cb: null });
  };

  return (
    <div className={styles.policyCreation}>
      {step === 0 && (
        <div className={styles.policyContainer}>
          <TagTemplateList setStep={setStep} setCurrentId={setCurrentId} />
          <PolicyTagTable setStep={setStep} setCurrentId={setCurrentId} />
        </div>
      )}
      {step === 1 && (
        <Policy
          onBack={() => {
            setStep(0);
            setCurrentId(null);
          }}
          currentId={currentId}
        />
      )}

      <div className={styles.buttonWrapper}>
        <Button
          onClick={() => {
            navigate(`/app/dashboard`);
          }}
          className={styles.button}
          variant="contained"
        >
          <Intl id="backToDashboard" />
        </Button>
      </div>

      <CallModal
        open={modalData.open}
        content={modalData.content}
        status={modalData.status}
        buttonClickHandle={modalData.cb}
        handleClose={closeModal}
      />
    </div>
  );
};

export default PolicyCreation;
