export default {
  // Global
  notFound: "404 Not Found",
  start: "Start",
  end: "End",
  approve: "Approve",
  reject: "Reject",
  uAreIn: "You are in ",
  workspace: "Workspace",
  previewAll: "Preview All",
  approveAll: "Approve All",
  rejectAll: "Reject All",
  requestList: "Request List",
  attachFile: "Attach A File",
  attachAnotherFile: "Attach Another File",
  multiple: "Multiple",
  edit: "Edit",
  reSubmit: "Resubmit",
  close: "Close",
  logining: "Loading ...",
  plsSelectRole: " Please select your role.",
  cancel: "Cancel",
  comment: "Comment",
  newComment: "Add New Comment",
  restoreField: "Restore Default Settings",
  sysField: "System Defined Field",
  dynamicApproval: "Dynamic Approval Field",
  system: "system",
  System: "System",
  expired: "expired",
  add: "Add",
  update: "Update",
  required: "Required",
  required_: "Required *",
  maxLength: "Maximum Length",
  itemWidth: "Item Width",
  search: "Search",
  apply: "Apply",
  addTableTag: "Add Table Tag(s)",
  modifyTableTag: "Modify Table Tag(s)",
  addColumnTag: "Add Column Tag(s)",
  modifyColumnTag: "Modify Column Tag(s)",
  CLEAR: "Clear",
  dropwfItem: "Drop your workflow here.",
  checkRequest: "See the request detail",
  confirmRaise: "Please confirm this request?",
  confirmSetup: "Please confirm this torro setting?",
  confirmOnboard: "Plaese confirm you want to onboard this data?",
  newRequestSubmit: "New request has been submitted!",
  selectedUc: "Please select a use case",
  checkTableTagInput: "Table tag has an empty value, please check your input.",
  confirmLogout: "Are you sure you want to exit the Torro?",
  gotNewRequest: "You have a new message!",
  notifyID: "Notification ID",
  request: "Request",
  time: "Time",
  youGot: "You got",
  unread: "Unread Messages",
  formName: "Form",
  msg: "Message",
  readAll: "Read All",
  done: "Done",
  country: "Country",
  region: "Region",
  countryList: "Country List",
  addCountry: "Add Country",
  workflowOperation: "Workflow Operation",
  workflow: "Workflow",
  group: "Group",
  wsAdgroup: "Workspace AD Group",
  workflowV: "Workflow Value",
  copy: "Copy",
  dataAccess: "Data access",
  loginPendingExpired: "Login session is going to be expired",
  loginExpired: "Login session has expired",
  logout: "Log out",
  stay: "Stay on",
  tableTags: "Table tags",
  tableSchema: "Table schema",
  viewLog: "view logs",
  noLogs: "No logs",

  // Admin page
  adminConfig: "Admin Configuration",
  adminConfirm: "Do you confirm to save the change?",
  newConfigChanged: "New config have been avaliable!",
  mainColor: "Main theme color",
  mainDColor: "Main dark theme color",
  mainLColor: "Main light theme color",
  timeZoneList: "Time Zone options",

  // data exploration
  exploration: "Data Exploration",
  dataExplore: "Data Exploration",

  // role
  it: "IT Admin",
  dg: "Data Governor",
  du: "Data User",
  serviceAdmin: "Root",

  // workspace
  wsCreate: "Create New Workspace",
  defaultAd: "Default AD Group List",
  addWs: "Add New Workspace",
  wsDetail: "Workspace Detail",
  confirmUpdateWs: "Please confirm this new workspace setting?",
  confirmAddWS: "Please confirm this new workspace creation?",
  wsIsAdd: "New workspace has been created.",
  wsUpdated: "Workspace has been updated.",
  wsId: "Workspace ID",
  wsName: "Workspace Name",
  wsMan: "Workspace Management",
  wsDeleted: "Workspace has been deleted.",
  confirmDeleteWs: "Please confirm the deletion of this workspace?",
  updateWs: "Update Workspace",
  createWs: "Create New Workspace",
  wsHeadGroup: "Workspace Owner Group",
  wsTeamGroup: "Workspace Team Group",
  regionStructure: "Region / Country Hierarchy",
  addRegion: "Add Region",
  systemField: "System Define Field",
  updateSystemField: "Update System Define Field",
  dynamicApprover: "Dynamic Approval Field",

  adminServiceAcc: "Admin Service Account",
  ucOwnerGroup: "Use Case Owner Group",
  ucTeamGroup: "Use Case Team Group",
  ucLabel: "Use Case Label",
  downLoadExcel: "Download Excel Template",
  uploadExcel: "Upload Default AD Group List",

  workspaceName: "Workspace Name",
  wsAD: "Workspace Owner AD Group",
  itAD: "IT Admin AD Group",
  dataGovernorAd: "Workspace Data Governance AD Group",
  wsTeamAD: "Workspace Team AD group",
  wsDes: "Workspace Description",
  userCycle: "User Recertification Cycle",
  ucFlow: "Use Case Approval Flow",
  addNewAD: "Add New AD Group List",
  limit150: "You cannot have more than 150 AD groups",

  // policy
  tags: "Tags",
  confirmUpdatePolicy: "Please confirm you want this policy?",
  confirmAddPolicy: "Please confirm you want to add this policy?",
  policyTags: "Policy Tag(s)",
  policyTagOr: "Policy Tag / Column access",
  ColumnTags: "Column Tag(s)",
  location: "Location",
  tagTemplateName: "Tag Template Name",
  templateId: "Template ID",
  formId: "Form ID",
  confirmDeleteTagTemplate:
    "Please confirm you want to delete this data tag template?",
  tagTemplateDeleted: "This tag template has been deleted?",

  // Use case
  useCase: "Use Case",
  usecaseInfo: "Use Case Info",
  useCaseDetail: "Use Case Detail",
  useCaseMember: "Use Case Members",
  ucId: "Use Case ID",
  ucList: "Use Case List",
  ucName: "Use Case Name",
  usOwnerGroup: "Use Case Owner Group",
  ValidityDate: "Validity Date",

  confirmDeleteUc: "Please confirm you want to delete this use case?",
  ucDeleted: "Use case has been deleted.",

  resourceName: "Resource name",
  resourceLabel: "Resource label",
  accessTime: "Access time",
  gcpResources: "GCP resources",

  // Policy
  policyMan: "Data Access Policy Management",
  setupPolicy: "Setup Workspace Data Policy",
  addPolicy: "Add New Policy",
  policyId: "Policy ID",
  policyName: "Policy Name",
  project: "Project",
  createPolicy: "Create Policy",
  policyTagStru: "Policy Tag Structure",
  addPolicyTag: "Add Policy Tag",
  addSubTag: "Add Subtag",
  deleteTag: "Delete",
  dataGovernor: "Data Governor Tag Template",
  addTagTemplate: "Add Tag Template",

  //Data Onboarding
  dataOnboarding: "Data Publishing",
  fieldName: "Field Fame",
  type: "Type",
  mode: "Mode",
  policyTag: "Policy Tags",
  description: "Description",
  resourceDetail: "Resource Detail",
  projectName: "Project name",
  dataSet: "Dataset",
  tableName: "Table name",
  fields: "Fields",
  applyTime: "Apply time",

  // Data Discovery
  dataDiscovery: "Data Discovery",
  filters: "Filters",
  lastModified: "Last Modified",

  // dashboard]
  frequentlyUsed: "Frequently used forms",
  requestId: "Request ID",
  historyId: "History ID",
  requestor: "Requestor",
  historyRecord: "Submission History",
  reOpen: "Re-open",
  requestDetail: "Request Detail",
  requestProgress: "Request Progress",
  commnetHistory: "Comment History",
  addComment: "Add Comment",

  addNewCommentTips: "Please confirm you want to add this comment?",
  deleteCommentTips: "Please confirm you want to delete this comment?",

  deleteSuccess: "Successfully deleted!",
  addSuccess: "Successfully added!",

  //requestDetail
  approveTips: "Please confirm your approve for this request?",
  rejectTips: "Please confirm your rejection of this request?",
  approvedBy: "Approved by: ",
  adGroup: "Ad group: ",

  // loginPage
  userName: "User Name",
  pwd: "Password",
  login: "Login",
  reset: "Reset",

  //orgSetting
  projectSetup: "Project Setup Config",
  setupDes: "Please enter the following information to create your project.",
  host: "Host",
  port: "Port",
  use_ssl: "Use SSL",
  admin: "Admin",
  admin_pwd: "Admin Password",
  cer_path: "Certification Path",
  admin_group: "Admin Group",
  base_group: "Viewer Group",
  org_name: "Organization Name",
  des: "Description",
  search_base: "Search Base",
  setup: "Setup",

  // app
  dashboard: "Dashboard",
  createWorkSpace: "Workspace Management",
  createUc: "Create Use Case",
  wsManagement: "Workspace",
  raiseTicket: "Raise A Request",
  ticketAction: "Workspace Services",
  pickStart: "Pick a service to start",
  submit: "Submit",

  lefNav_create: "Create New Use Case",
  letfNav_add: "Add User to Use Case",
  leftNav_requset: "Request Data Access",
  leftNav_jupyter: "Request Jupyter Notebook",
  leftNav_addData: "Add New Data Source",
  leftNav_support: "Raise Support Ticket",

  formManagement: "Form Design",
  workflowManagement: "Workflow Setup",
  bashCommand: "Terminal",

  save: "Save",
  back: "Back",
  backToDashboard: "Back to dashboard",
  delete: "Delete",

  /* moduleEdit */
  template: "Template",
  advanceOptions: "Advance Options",
  label: "Label",
  value: "Value",
  placeholder: "Placeholder",

  default: "Default",
  defaultvalue: "Default Value",
  options: "Options",
  addField: "Add Field",
  addForm: "Add Form",

  /* workflowManagement */
  plsSelectForm: "Please select a form to start a new workflow",
  addWorkflow: "Add New Workflow",
  formTrigger: "Form Trigger",
  workflowId: "Workflow ID",
  workflowName: "Workflow Name",
  associatedForm: "Associated Form",
  createtime: "Create Time",
  modefiedTime: "Modified Time",
  operation: "Operation",
  dropItem: "Drop Item Here",
  plsClickEdit: "Please click edit to start edit item Here.",
  dropCondtion: "Drop condition(s) here",

  name: "Name",
  plsAddSchema: "Please add your data schema.",

  workflowItems: "Workflow Items",
  itemConditions: "Items Conditions",
  noneConditions: "No Optional Conditions",

  //visualisation
  visualisation: "Visualisation",

  //getDataAccess
  getDataAccess: "Data Consumption Request",
  dataAccessCart: "List Of Request(s)",
  addToCart: "Add To Cart",
  orderNow: "Submit For Approval",

  /* callModel*/
  tryAgain: "Try Again",
  continue: "Continue",
  confirm: "Confirm",
  almost: "Almost There!",
  ooops: "Ooops!",
  allDone: "All done!",
  plsWait: "Please wait",
  checkboxItem: "Checkbox Items",
  confirmNewWorkflow: "Please confirm new workflow addition.",
  workflowCreated: "New workflow has been created.",
  workflowRemove: "Please confirm this workflow removal.",
  submitting: "Submitted and appreciate your patience.",
  confirmDeleteForm: "Please confirm this form deletion.",
  formDeleted: "Your form is deleted.",
  workflowDelete: "This workflow has been deleted.",

  /* error msg */
  msg404: "Resource not found +_+;; Please contact the IT Admin.",
  msg401:
    "If you believe you should have access to this application, please contact your IT admin ",
  goesWrong: "Something went wrong @_@;;",
  checkInput:
    "Something is wrong with your input, please verify your input and try again",

  loadNpatience: "This page is reloading, and appreciate your patience.",

  /* form error handle */
  textEmptpy: "Please Enter ",
  selectEmpty: "Please Select ",
  dateEmpty: "Please Choose ",

  // Batch upload
  batchUpload: "Batch Upload Data",
  bactchDes:
    "Batch upload function, can batch upload data policy and batch do data publishing",
  batchUploadTemplate: "Batch Upload Template",
  batchPolicy: "Batch upload data policy",
  batchOnboading: "Batch data onboarding",
  performBatch: "Perform Batch upload",
  clickOrDrag: "Click or drag file to this area to upload",
  supportSingle: "Support for a single file upload.",
  excelFormat: "Only Accept Excel format",
  batchRun: "Batch Run",
  bactchTaskLikst: "Batch tasks list",
  noBatchTask: "No batch task found.",
};
