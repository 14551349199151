/* third lib*/
import React, { useState, useCallback, useMemo } from "react";
import styles from "./styles.module.scss";
import cn from "classnames";
import { FormattedMessage as Intl } from "react-intl";

/* local Component*/
import Frequently from "./Frequently";
import RecordTable from "./RecordTable";
import Text from "@basics/Text";
import { useGlobalContext } from "src/context";
import { GOVERNOR, IT, ADMIN, USER } from "src/lib/data/roleType.js";
const ROOT = "/app";
const DataUserView = () => {
  const { authContext } = useGlobalContext();
  const [currentTab, setCurrentTab] = useState(2);

  const tabList = [
    { label: "Your Requests", value: 2 },
    { label: "Waiting Your Approval", value: 3 },
  ];

  const favoritLlinks = useMemo(() => {
    switch (authContext.role) {
      case IT:
        return [
          {
            id: "wsMan",
            title: <Intl id="wsMan" />,
            link: `${ROOT}/WorkspaceManage`,
          },
        ];
      case ADMIN:
        return [
          {
            id: "wsMan",
            title: <Intl id="wsMan" />,
            link: `${ROOT}/WorkspaceManage`,
          },
        ];
      case GOVERNOR:
        return [
          {
            id: "policyMan",
            title: <Intl id="policyMan" />,
            link: `${ROOT}/policyCreation`,
          },
          {
            id: "dataOnboarding",
            title: <Intl id="dataOnboarding" />,
            link: `${ROOT}/dataOnboarding`,
          },
          {
            id: "exploration",
            title: <Intl id="exploration" />,
            link: `${ROOT}/exploration`,
          },
          {
            id: "batchUpload",
            title: <Intl id="batchUpload" />,
            link: `${ROOT}/batchUpload`,
          },
        ];
      case USER:
        return [
          {
            id: "createUc",
            title: <Intl id="createUc" />,
            link: `${ROOT}/forms?id=2`,
          },
          {
            id: "getDataAccess",
            title: <Intl id="getDataAccess" />,
            link: `${ROOT}/getDataAccess`,
          },
        ];

      default:
        return [];
    }
  }, [authContext.role]);

  const tabClickHandle = useCallback((value) => {
    setCurrentTab(value);
  }, []);

  const currentContent = useCallback(() => {
    switch (currentTab) {
      case 2:
        return <RecordTable />;
      case 3:
        return <RecordTable approved />;
      default:
        return <></>;
    }
  }, [currentTab]);

  return (
    <div className={styles.dataUserView}>
      <div className={styles.dataContent}>
        <div className={styles.frequentlyUsed}>
          <Text type="subTitle">
            <Intl id="frequentlyUsed" />:
          </Text>
          <Frequently favoritLlinks={favoritLlinks} />
        </div>
        <div className={styles.tab}>
          {tabList.map((item) => {
            return (
              <div
                key={item.label}
                onClick={() => {
                  tabClickHandle(item.value);
                }}
                className={cn(styles.tabItem, {
                  [styles["active"]]: item.value === currentTab,
                })}
              >
                <Text type="subTitle">{item.label}</Text>
              </div>
            );
          })}
        </div>
        <div className={styles.tabContent}>{currentContent()}</div>
      </div>
    </div>
  );
};

export default DataUserView;
