/* third lib*/
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { FormattedMessage as Intl } from "react-intl";
import { useForm, useWatch } from "react-hook-form";
import ScrollBar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";

/* material-ui */
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

/* local components & methods */
import { getQueryString } from "src/utils/url-util.js";
import styles from "./styles.module.scss";
import FormItem from "@comp/FormItem";
import HeadLine from "@basics/HeadLine";
import Text from "@basics/Text";
import Loading from "@assets/icons/Loading";
import Button from "@basics/Button";
import ResourceDetail from "@comp/ResourceDetail";
import {
  getOnBoardDataForm,
  getTableSchema,
  getHiveResource,
  getPolicys,
  getTags,
} from "@lib/api";
import { sendNotify } from "src/utils/systerm-error";
import TableTagDisplay from "@comp/TableTag";
import TableSchema from "./TableSchema";
import { useGlobalContext } from "src/context";
import ShoppingCart from "./ShoppingCart";

const GetDataAccess = () => {
  const resType = getQueryString("type");
  const projectId = getQueryString("id");
  const datasetName = getQueryString("dataset");
  const tableName = getQueryString("name");
  const autoAdd = getQueryString("autoAdd");

  const navigate = useNavigate();

  const { handleSubmit, control, register } = useForm({
    defaultValues: {
      datasetName: datasetName || "",
      projectId: projectId || "",
      resourceType: resType || "GCP",
      tableName: tableName || "",
    },
  }); // initialise the hook

  let defaultQuery = null;
  if (resType && projectId && resType && tableName) {
    defaultQuery = {
      datasetName: datasetName,
      projectId: projectId,
      resourceType: resType,
      tableName: tableName,
    };
  }

  const resourceType = useWatch({
    control,
    name: "resourceType",
    defaultValue: resType || "GCP",
  });

  const { cartContext, setCartContext, addCartItem } = useGlobalContext();

  const [formLoading, setFormLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [policys, setPolicys] = useState([]);
  const [tagTemplateList, setTagTempalteList] = useState([]);
  const [onBoardDataForm, setOnBoardDataForm] = useState(null);
  const [selectedList, setSelectedList] = useState([]);

  const cartList = useMemo(() => {
    return cartContext.cartList;
  }, [cartContext]);

  const tableForm = useMemo(() => {
    if (!onBoardDataForm) {
      return [];
    }
    return resourceType === "GCP" ? onBoardDataForm.gcp : onBoardDataForm.hive;
  }, [resourceType, onBoardDataForm]);

  const tableList = useMemo(() => {
    return tableData?.schema?.fields;
  }, [tableData]);

  const submitHandle = (data) => {
    setSearchQuery(data);
  };

  const policyMap = useMemo(() => {
    let map = {};
    if (policys.length > 0) {
      policys.forEach((item) => {
        if (item.policy_tags_dict) {
          map = {
            ...map,
            ...item.policy_tags_dict,
          };
        }
      });
    }
    return map;
  }, [policys]);

  const renderFormItem = (items, disabled) => {
    return items.map((item, index) => {
      return (
        <FormItem
          key={index}
          data={item}
          index={index}
          control={control}
          register={register}
          disabled={disabled}
        />
      );
    });
  };

  const addCartHandle = useCallback(
    (selectedList) => {
      if (tableData.seq != null) {
        let tmp = [...cartList];
        tmp.splice(tableData.seq, 1, {
          ...tableData,
          selectedList: selectedList,
        });
        setCartContext({
          cartList: tmp,
        });
      } else {
        let exist;
        cartList.forEach((item) => {
          if (
            item?.tableReference.projectId ===
              tableData?.tableReference.projectId &&
            item?.tableReference.datasetId ===
              tableData?.tableReference.datasetId &&
            item?.tableReference.tableId === tableData?.tableReference.tableId
          ) {
            exist = true;
          }
        });
        if (exist) {
          sendNotify({
            msg: "Have exist data resouce in cart list",
            status: 3,
            show: true,
          });
          return;
        }
        setCartContext({
          cartList: [
            ...cartList,
            {
              ...tableData,
              seq: cartList.length,
              selectedList: selectedList,
            },
          ],
        });
      }

      setTableData(null);
      setSelectedList([]);
    },
    [tableData, cartList, setCartContext]
  );

  const showBackItem = useCallback((item) => {
    setTableData(item);
    setSelectedList(item.selectedList);
  }, []);

  useEffect(() => {
    if (searchQuery) {
      let postData = { ...searchQuery };
      let apiCall = resourceType === "GCP" ? getTableSchema : getHiveResource;
      setFormLoading(true);
      apiCall(postData)
        .then((res) => {
          setTableData(res.data);
          setFormLoading(false);
        })
        .catch((e) => {
          sendNotify({
            msg: e.message,
            status: 3,
            show: true,
          });
        });
    }
  }, [searchQuery, resourceType]);

  useEffect(() => {
    if (resType && projectId && resType && tableName) {
      let apiCall = resType === "GCP" ? getTableSchema : getHiveResource;
      setFormLoading(true);
      apiCall({
        datasetName: datasetName,
        projectId: projectId,
        resourceType: resType,
        tableName: tableName,
      })
        .then((res) => {
          if (autoAdd) {
            addCartItem({
              ...res.data,
              seq: 0,
              selectedList: [],
            });
            setFormLoading(false);
          } else {
            setTableData(res.data);
            setFormLoading(false);
          }
        })
        .catch((e) => {
          sendNotify({
            msg: e.message,
            status: 3,
            show: true,
          });
        });
    }
    /* eslint-disable */
  }, [datasetName, projectId, resType, tableName, autoAdd]);
  /* eslint-disable */

  useEffect(() => {
    getPolicys()
      .then((res) => {
        setPolicys(res.data);
      })
      .catch((e) => {
        sendNotify({
          msg: "Get Policy tags error.",
          status: 3,
          show: true,
        });
      });
  }, []);

  useEffect(() => {
    getTags()
      .then((res) => {
        setTagTempalteList(res.data);
      })
      .catch((e) => {
        sendNotify({
          msg: "Get Policy tags error.",
          status: 3,
          show: true,
        });
      });
  }, []);

  useEffect(() => {
    getOnBoardDataForm()
      .then((res) => {
        if (res.data) {
          setOnBoardDataForm(res.data);
        }
      })
      .catch((e) => {
        sendNotify({
          msg: "Get Policy tags error.",
          status: 3,
          show: true,
        });
      });
  }, []);

  return (
    <div className={styles.dataDiscover}>
      <div className={styles.dataLeftPanel}>
        <ScrollBar>
          <div className={styles.leftPanelContainer}>
            {defaultQuery && (
              <div
                className={styles.backWrapper}
                onClick={() => {
                  window.history.back();
                }}
              >
                <div className={styles.onBack}>
                  <ArrowBackIcon />
                  <Text type="subTitle">
                    <Intl id="back" />
                  </Text>
                </div>
              </div>
            )}
            <div className={styles.title}>
              <HeadLine>
                <Intl id="getDataAccess" />
              </HeadLine>
            </div>

            <form
              className={styles.tableSearch}
              id="tableSearch"
              onSubmit={handleSubmit(submitHandle)}
              defaultValue
            >
              <div className={styles.formOptions}>
                {renderFormItem(tableForm)}
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  onClick={() => {
                    navigate(`/app/dashboard`);
                  }}
                  className={styles.button}
                  text
                >
                  <Intl id="backToDashboard" />
                </Button>
                <Button
                  className={styles.button}
                  type="submit"
                  variant="contained"
                >
                  <Intl id="search" />
                </Button>
              </div>
            </form>
            {formLoading && <Loading></Loading>}
            {!formLoading && tableList && (
              <>
                <div className={styles.secondTitle}>
                  <Text type="title">
                    <Intl id="resourceDetail" />
                  </Text>
                </div>
                <ResourceDetail tableData={tableData} />
                {tableData.tags && tableData.tags.length > 0 && (
                  <div>
                    <div className={styles.secondTitle}>
                      <Text type="title">Tags ({tableData.tags.length})</Text>
                    </div>
                    <div className={styles.tableTagList}>
                      {tableData.tags.map((tag, index) => {
                        return <TableTagDisplay key={index} tagData={tag} />;
                      })}
                    </div>
                  </div>
                )}
                <TableSchema
                  tableData={tableData}
                  tagTemplateList={tagTemplateList}
                  policyMap={policyMap}
                  addCartHandle={addCartHandle}
                  alreadySelected={selectedList}
                />
              </>
            )}
          </div>
        </ScrollBar>
      </div>
      <div className={styles.dataRightPanel}>
        <ShoppingCart showBackItem={showBackItem} />
      </div>
    </div>
  );
};

export default GetDataAccess;
