/* third lib*/
import React, { useRef, useState, useCallback, useMemo } from "react";
import { FormattedMessage as Intl } from "react-intl";
import { useDropzone } from "react-dropzone";

/* material-ui */
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import PublishIcon from "@material-ui/icons/Publish";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Tabs,
  Tab,
} from "@material-ui/core";
/* local components & methods */
import NotAuthorized from "../Errors/NotAuthorized";
import Text from "@basics/Text";
import HeadLine from "@basics/HeadLine";
import styles from "./styles.module.scss";
import { fileTemplates } from "src/constant";
import ExcelFile from "src/assets/icons/ExcelFile";
import Button from "@basics/Button";
import Loading from "@assets/icons/Loading";
import { useGlobalContext } from "src/context";
import { GOVERNOR, IT, ADMIN } from "src/lib/data/roleType.js";

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontSize: "14px",
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const BatchUpload = () => {
  const { authContext } = useGlobalContext();
  const haveAuth = useMemo(() => {
    return [ADMIN, GOVERNOR, IT].includes(authContext.role);
  }, [authContext.role]);
  const inputRef = useRef(null);
  const [acceptedFile, setAcceptedFile] = useState(null);
  const [taskList, setTaskList] = useState([]);
  const [currentTab, setCurrentTab] = useState("policy");
  const tabList = [
    { label: <Intl id="batchPolicy" />, value: "policy" },
    { label: <Intl id="batchOnboading" />, value: "data" },
  ];

  const fileChoosen = !!acceptedFile;
  const disbaleFileSelect = false;
  const tabClickHandle = useCallback((event, value) => {
    setCurrentTab(value);
    setAcceptedFile(null);
  }, []);

  const handleDownload = (fileUrl, fileName) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onDrop = (acceptedFile) => {
    setAcceptedFile(acceptedFile[0]);
  };

  const handleUpload = () => {
    if (acceptedFile) {
      setTaskList([
        ...taskList,
        {
          status: "loading",
          file: acceptedFile,
        },
      ]);
      setAcceptedFile(null);
    }
  };

  const { getInputProps, getRootProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  });

  const handleButtonClick = () => {
    inputRef.current.click();
  };
  if (haveAuth) {
    return (
      <div className={styles.visualisation}>
        <div className={styles.dataContainer}>
          <div className={styles.title}>
            <HeadLine>
              <Intl id="batchUpload" />
            </HeadLine>

            <div className={styles.description}>
              <Intl id="bactchDes" />
            </div>
            <div className={styles.uploadContainer}>
              <div className={styles.leftContainer}>
                <div className={styles.uploadTemplate}>
                  <Text type="title">
                    <Intl id="batchUploadTemplate" />
                  </Text>
                  <div className={styles.fileTemplates}>
                    {fileTemplates.map((item, index) => {
                      return (
                        <Tooltip
                          title={
                            <span
                              style={{ fontSize: "12px" }}
                            >{`Download ${item.fileName}`}</span>
                          }
                          arrow
                        >
                          <div
                            key={index}
                            onClick={() => {
                              handleDownload(item.fileUrl, item.fileName);
                            }}
                            className={styles.fileTemplateItem}
                          >
                            <ExcelFile />
                            <span className={styles.fileName}>
                              {item.fileName}
                            </span>
                          </div>
                        </Tooltip>
                      );
                    })}
                  </div>
                </div>
                <div className={styles.processUpload}>
                  <Text type="title">
                    <Intl id="performBatch" />
                  </Text>
                  <div>
                    <Tabs
                      value={currentTab}
                      onChange={tabClickHandle}
                      indicatorColor="primary"
                      textColor="primary"
                      centered
                    >
                      {tabList.map((tab) => {
                        return <AntTab label={tab.label} value={tab.value} />;
                      })}
                    </Tabs>
                  </div>

                  <div key={currentTab} className={styles.fileTemplates}>
                    <div
                      {...getRootProps({ className: "dropzone" })}
                      className={styles.uploadFileArea}
                      onClick={handleButtonClick}
                    >
                      <input
                        {...getInputProps({
                          ref: inputRef,
                          disabled: disbaleFileSelect,
                        })}
                      />
                      <div className={styles.uploadFileMask}>
                        <PublishIcon />
                        {fileChoosen ? (
                          <p className={styles.fileSelected}>
                            <ExcelFile /> {acceptedFile.name} Selected
                          </p>
                        ) : (
                          <p>
                            <Intl id="clickOrDrag" />
                          </p>
                        )}

                        <p className={styles.desc}>
                          <Intl id="supportSingle" />
                        </p>
                        <p className={styles.desc}>
                          <Intl id="excelFormat" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.batchRun}>
                  <Button disabled={!fileChoosen} onClick={handleUpload}>
                    <Intl id="batchRun" />
                  </Button>
                </div>
              </div>
              <div className={styles.rightContainer}>
                <div className={styles.uploadTemplate}>
                  <Text type="title">
                    <Intl id="bactchTaskLikst" />
                  </Text>
                  <div className={styles.batchTaskList}>
                    {taskList.length > 0 ? (
                      <List component="nav">
                        {taskList.map((item) => {
                          return (
                            <ListItem button>
                              <ListItemIcon>
                                <ExcelFile />
                              </ListItemIcon>
                              <ListItemText primary={item.file.name} />
                              <ListItemSecondaryAction
                                className={styles.fileStatusIcon}
                              >
                                <Loading />
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                      </List>
                    ) : (
                      <div className={styles.empty}>
                        <Intl id="noBatchTask" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <NotAuthorized />;
  }
};

export default BatchUpload;
